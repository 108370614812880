import { Flex, Spinner } from "flicket-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import { NextComponentType } from "next";
import Router from "next/router";
import { useEffect, useState } from "react";
import { useAccount } from "~hooks";

const getDisplayName = (Component: NextComponentType) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Component.displayName || Component.name || "Component";

interface Options {
  redirect?: boolean;
}

export function withAuth(Component: NextComponentType, options: Options = {}) {
  const AuthComponent = (props: any) => {
    const {
      user,
      isLoggedIn,
      hasCheckedLogin,
      isTwoFactorAuthenticated,
      isInitialising,
      hasLoaded,
    } = useAccount();

    const [allowAuth, setAllowAuth] = useState(false);

    console.debug("withAuth", {
      user,
      isLoggedIn,
      hasCheckedLogin,
      isTwoFactorAuthenticated,
      isInitialising,
      hasLoaded,
    });

    useEffect(() => {
      if (
        hasCheckedLogin &&
        // when hasLoaded is true, we know that the user is logged in and we have fetched the user data
        // when isInitialising is false, we know that the user is not logged in
        (hasLoaded || isInitialising === false)
      ) {
        if (
          isLoggedIn &&
          user.isTwoFactorAuthenticationEnabled &&
          !isTwoFactorAuthenticated
        ) {
          void Router.replace({ pathname: `/authenticate` });
        } else if (!isLoggedIn) {
          const url = new URL(props.url as string);
          void Router.push({
            pathname: "/login",
            query: options.redirect
              ? {
                  redirect: url.pathname + url.search,
                }
              : "",
          });
        } else {
          setAllowAuth(true);
        }
      }
    }, [
      hasCheckedLogin,
      user,
      isLoggedIn,
      isTwoFactorAuthenticated,
      hasLoaded,
      isInitialising,
    ]);

    if (!allowAuth) {
      return (
        <Flex variant="center" flex="1" p={4} flexDirection="column">
          <Spinner size={48} color="P300" data-testid="spinner" />
        </Flex>
      );
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
